import { Component, OnInit, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { CardUser, InscrUser } from 'src/app/models/card.model';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  cardUploadDone: boolean = false;
  cardUploadDate: string = "";
  cardError: string = "";
  //cardUserBorads: Array<CardUser[]> = [];
  cardUserBorads: CardUser[] = [];
  countElem: number = 0;
  totalPDFPages: number = 0;
  gestInscr: boolean = false;
  startGestInscrProcess: boolean = false;
  printCard: boolean = false;
  processingUserLst: InscrUser[] = [];
  processingUserDtLst: string[] = [];
  selectedFilter: string = "";
  validInscrCount: number = 0;

  fileTreat = "";
  fileInscrFilter = "";
  fileInscrSite = "";
  fileInscrValid = "";

  constructor(
    private datepipe: DatePipe
  ) {
    window.onafterprint = (e) => {
      this.closePrintCard();
    };
  }

  ngOnInit() {
  }

  public fileUploadTreat = new FileUploadControl(FileUploadValidators.filesLimit(1));
  public fileUploadInscrFilter = new FileUploadControl(FileUploadValidators.filesLimit(1));
  public fileUploadInscrSite = new FileUploadControl(FileUploadValidators.filesLimit(1));
  public fileUploadInscrValid = new FileUploadControl(FileUploadValidators.filesLimit(1));

  reset() {
    this.cardUserBorads = [];
  }

  filterSelect(filter: string) {
    if (this.selectedFilter === filter) {
      this.selectedFilter = "";
    } else {
      this.selectedFilter = filter;
    }
  }

  /**
   * Action lors du click sur la checkbox de sélection/déselection d'un utilisateur
   */
  gestInscrSelectAction(idx: number) {
    let user = this.processingUserLst[idx];
    if (user.processingDt) {
      user.processingDt = "";
      this.validInscrCount--;
    } else {
      let dt = this.datepipe.transform(new Date(), 'yyyy/MM/dd');
      user.processingDt = dt;
      this.validInscrCount++;
    }
  }

  /**
   * Démarrage du traitement des inscrits
   */
  startGestInscr() {
    this.processingSourceFileForGestInscr();
    this.startGestInscrProcess = true;
  }

  /**
   * Enregistrement des informations sur les utilisateurs inscrits et enregistrement pour permettre
   * une utilisation ulterieure si nécessaire
   */
  saveGestInscr() {
    let fileData: File = this.fileUploadInscrSite.valueChanges.getValue()[0];
    let fileName: string = fileData.name.replace(/\.csv/, '') + '-valides';
    this.downloadFile(fileName);
    //this.gestInscrDiscard();
  }

  /**
   * Exporte les adresses mails des utilisateurs inscrits au club
   */
  saveMails() {
    let fileData: File = this.fileUploadInscrSite.valueChanges.getValue()[0];
    let fileName: string = fileData.name.replace(/\.csv/, '') + '-mails';
    this.downloadFile(fileName, true);
  }

  /**
   * Ferme l'initialisation des informations sur les utilisateurs inscrits pour lesquels il faut générer une carte
   */
  gestInscrDiscard() {
    this.fileUploadInscrSite = new FileUploadControl(FileUploadValidators.filesLimit(1));
    this.fileUploadInscrValid = new FileUploadControl(FileUploadValidators.filesLimit(1));
    this.fileInscrSite = "";
    this.fileInscrValid = "";
    this.gestInscr = false;
    this.startGestInscrProcess = false;
    this.processingUserLst = [];
    this.processingUserDtLst = [];
    this.selectedFilter = "";
  }

  /**
   * Initialisation des informations sur les utilisateurs inscrits pour lesquels il faut générer une carte
   */
  selecteInscr() {
    this.processingSourceFileForGestInscr();
    this.gestInscr = true;
  }

  /**
   * Initialisation des infos contenues dans les fichiers uploadés
   * @param event 
   * @param type 
   */
  fileUploadInit(event, type) {
    let fileData: File;
    var reader = new FileReader();
    if (type === 'Treat') {
      fileData = this.fileUploadTreat.valueChanges.getValue()[0];
    } else if (type === 'InscrFilter') {
      fileData = this.fileUploadInscrFilter.valueChanges.getValue()[0];
    } else if (type === 'Site') {
      fileData = this.fileUploadInscrSite.valueChanges.getValue()[0];
    } else {
      fileData = this.fileUploadInscrValid.valueChanges.getValue()[0];
    } 
    reader.readAsText(fileData);
    reader.onload = (e) => {
      if (typeof reader.result === 'string') {
        if (type === 'Treat') {
          this.fileTreat = reader.result;
        } else if (type === 'InscrFilter') {
          this.fileInscrFilter = reader.result;
        } else if (type === 'Site') {
          this.fileInscrSite = reader.result;
        } else {
          this.fileInscrValid = reader.result;
        }
      }
    }     
  }

  /**
   * Initialisation de l'affichage de la liste des utilisateurs inscrits pour pouvoir définir 
   * ceux pour qui il faut générer des cartes
   */
  processingSourceFileForGestInscr() {
    let validInscrMap: Map<string, string> = new Map();
    // Traitement du fichier des inscrits du site
    let siteInscrLst = this.fileInscrSite.replace(/ , |, | ,/g,",").split(/\r\n|\n/); 
    // Traitement du fichier des inscrits validés
    if (this.fileInscrValid) {
      let lineClean = this.fileInscrValid.replace(/ ; |; | ;/g,";");
      let validInscrLst = lineClean.split(/\r\n|\n/);
      for (let i = 0; i < validInscrLst.length; i++) {
        let infos = validInscrLst[i].split(';');
        validInscrMap.set(infos[0] + ' ' + infos[1], infos[2]);
      }
    }
    for (let i = 1; i < siteInscrLst.length; i++) {
      let infos = siteInscrLst[i].split('","'); 
      let typeInscr = infos[0].replace('\"Inscription IPSO Savate Boxe Française (', '').replace(')', '');
      let processingDt = validInscrMap.get(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase());
      if (!processingDt) {
        processingDt = "";
      }
      if (processingDt && !this.processingUserDtLst.includes(processingDt)) {
        this.processingUserDtLst.push(processingDt);
      }
      let ajout = 0;
      if (infos[30].match(/[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/)) {
        ajout = 1;
      }
      let numInscrNumb = 38 + ajout;
      let mail = infos[15];
      let photo: string;
      if (typeInscr == 'enfant') {
        if (infos[35].startsWith('data:image/jpeg;base64,') || infos[35].match(/https:\/\/.*\/wp-content\/uploads\/.*.png/)) {
          photo = infos[35];
          numInscrNumb = 43;
        } else {
          photo = infos[34];
          if (infos[36 + ajout].startsWith('{""type"":')) {
            numInscrNumb = 42;
          }
        }
      } else {
        photo = infos[30 + ajout];
        if (!infos[32 + ajout].startsWith('{""type"":')) {
          numInscrNumb = 34;
        }
      }
      let numInscrVal = infos[numInscrNumb];
      if (numInscrVal === '') {
        numInscrVal = '5959-' + infos[7].substr(0,2).toUpperCase() + infos[8].substr(0,2).toLowerCase() + '-a78b'
      }
      if (processingDt) {
        this.validInscrCount ++;
      }
      //console.info(infos);
      //console.info('{"nom": "' + infos[7].toLowerCase() + '", "prenom": "' + infos[8].toLowerCase() + '", "photo": "' + photo + '", "processingDt": "' + processingDt + '", "lock": ' + (processingDt? 'true': 'false') + ', "numInscrVal": "' + numInscrVal + '", "mail": "' + mail + '"}');
      this.processingUserLst.push(JSON.parse('{"nom": "' + infos[7].toLowerCase() + '", "prenom": "' + infos[8].toLowerCase() + '", "photo": "' + photo + '", "processingDt": "' + processingDt + '", "lock": ' + (processingDt? 'true': 'false') + ', "numInscrVal": "' + numInscrVal + '", "mail": "' + mail + '"}'));
      this.processingUserLst.sort((a, b) => (a.nom > b.nom) ? 1 : (a.nom === b.nom) ? ((a.prenom > b.prenom) ? 1 : -1) : -1 );
    }
    //console.info('----->',this.processingUserLst);
  }

  /**
   * Valide le texte qui est saisie dans le champ texte devant acceuillir les données à formater au format JSON
   * avant de générer les cartes
   * @param ev 
   */
  validateData() {
    try {
    this.cardUserBorads = [];
    let cardInfosLst = [];
    let include = [];
    let exclude = [];
    let validInscrMap: Map<string, string> = new Map();
    // Traitement du fichier source
    let lines = this.fileTreat.replace(/ , |, | ,/g,",").split(/\r\n|\n/);
    // Traitement des personnes à inclure
    if (this.fileInscrFilter) {
      let lineClean = this.fileInscrFilter.replace(/ ; |; | ;/g,";");
      let validInscrLst = lineClean.split(/\r\n|\n/);
      for (let i = 0; i < validInscrLst.length; i++) {
        let infos = validInscrLst[i].split(';');
        validInscrMap.set(infos[0] + ' ' + infos[1], infos[2]);
      }
    }
    let cardBoardContent: CardUser[] = [];
    let elemCount: number = 0;
    for (let i = 1; i < lines.length; i++) {
      let infos = lines[i].split('","');
      let processingDt = validInscrMap.get(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase());
      if (!processingDt) {
        processingDt = "";
      }
      if (processingDt && !this.processingUserDtLst.includes(processingDt)) {
        this.processingUserDtLst.push(processingDt);
      }
      //console.info('-' + i + '--infos->', infos);
      let typeInscr = infos[0].replace('\"Inscription IPSO Savate Boxe Française (', '').replace(')', '');
      let cardInfos = '';
      if (typeInscr == 'enfant') {
        //console.info('----->' + infos[7].toLowerCase() + ' ' + infos[8].toLowerCase(), !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase()));
        if (include.length > 0 && !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase())) {
          continue;
        }
        typeInscr = this.strUcFirst(typeInscr);
        let numInscrNumb = 42;
        if (!infos[36].startsWith('{""type"":')) {
          numInscrNumb = 38;
        }
        let photo = infos[34];
        if (infos[35].startsWith('data:image/jpeg;base64,') || infos[35].match(/https:\/\/.*\/wp-content\/uploads\/.*.png/)) {
          photo = infos[35];
          numInscrNumb = 43;
        }
        let numInscrVal = infos[numInscrNumb];
        cardInfos = '{"type": "' + typeInscr + 
                  '", "nom": "' + this.strUcFirst(infos[7].toLowerCase()) +
                  '", "prenom": "' + this.strUcFirst(infos[8].toLowerCase()) +
                  '", "numInscr": "' + numInscrVal +
                  '", "photo": "' + photo +
                  '", "processingDt": "' + processingDt +
                  '"}'
        //console.info('-' + i + '--cardInfos-->', cardInfos);
      } else {
        //console.info('----->' + infos[7].toLowerCase() + ' ' + infos[8].toLowerCase(), !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase()));
        if (include.length > 0 && !include.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase())) {
          continue;
        }
        if (exclude.length > 0 && exclude.includes(infos[7].toLowerCase() + ' ' + infos[8].toLowerCase())) {
          continue;
        }
        let ajout = 0;
        if (infos[30].match(/[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]/)) {
          ajout = 1;
        }
        let numInscrNumb = 38 + ajout;
        if (!infos[32 + ajout].startsWith('{""type"":')) {
          numInscrNumb = 34;
        }
        let numInscrVal = infos[numInscrNumb];
        if (numInscrVal === '') {
          numInscrVal = '5959-' + infos[7].substr(0,2).toUpperCase() + infos[8].substr(0,2).toLowerCase() + '-a78b'
        }
      
        cardInfos = '{"type": "' + typeInscr + 
                    '", "nom": "' + this.strUcFirst(infos[7].toLowerCase()) +
                    '", "prenom": "' + this.strUcFirst(infos[8].toLowerCase()) +
                    '", "numInscr": "' + numInscrVal +
                    '", "photo": "' + infos[30 + ajout] +
                    '", "processingDt": "' + processingDt +
                    '"}'
        //console.info('-' + i + '--cardInfos-->', cardInfos);
      }
      /*if (elemCount === 8) {
        this.cardUserBorads.push(JSON.parse(JSON.stringify(cardBoardContent)));
        cardBoardContent = [];
        elemCount = 0;
      }
      cardBoardContent.push(JSON.parse(cardInfos));*/
      this.cardUserBorads.push(JSON.parse(cardInfos));
      elemCount++;
    }
    /*if (cardBoardContent.length > 0) {
      this.cardUserBorads.push(JSON.parse(JSON.stringify(cardBoardContent)));
    }*/
    //console.info('---->',this.cardUserBorads);
    this.cardUserBorads.sort((a, b) => (a.nom > b.nom) ? 1 : (a.nom === b.nom) ? ((a.prenom > b.prenom) ? 1 : -1) : -1 );
    this.totalPDFPages = this.cardUserBorads.length;
    } catch(e) {
      console.info('---ERROR--', e);
      this.cardError = "La valeur saisie dans le champ est incorrecte !"
    }
  }

  /**
   * Passe la première lettre en majuscule
   * @param a 
   */
  private strUcFirst(a) {
    return (a+'').charAt(0).toUpperCase()+a.substr(1);
  }

/**
 * Transforme la liste des utilisateurs qui ont validé leur inscription en fichier csv
 * @param objArray 
 */
ConvertInscrValidToCSV() { 
  let str = ''; 
  for (let i = 0; i < this.processingUserLst.length; i++) { 
    let user: InscrUser = this.processingUserLst[i];
    if (user.processingDt !== '') {   
      str += user.nom + ";" + user.prenom + ";" + user.processingDt + "\r\n"; 
    }
  } 
  return str; 
} 

/**
 * Création d'un fichier CSV contenant les Mails des utilisateurs dont l'inscription au club est validée
 */
ConvertInscrValidMailToCSV() {
  let str = '';
  for (let i = 0; i < this.processingUserLst.length; i++) {
    let user: InscrUser = this.processingUserLst[i];
    if (user.processingDt !== '') {   
      str += user.nom + ";" + user.prenom + ";" + user.mail.toLowerCase() + "\r\n"; 
    }
  } 
  return str; 
}

/**
 * Lance le Téléchargement du fichier généré
 * @param filename 
 */
downloadFile(filename = 'data', exportMail = false) { 
  let csvData = '';
  if (exportMail) {
    csvData = this.ConvertInscrValidMailToCSV(); 
  } else {
    csvData = this.ConvertInscrValidToCSV(); 
  }
	let blob = new Blob(['\ufeff' + csvData], { 
		type: 'text/csv;charset=utf-8;'
	}); 
	let dwldLink = document.createElement("a"); 
	let url = URL.createObjectURL(blob); 
	let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1; 
	navigator.userAgent.indexOf('Chrome') == -1; 
	
	//if Safari open in new window to save file with random filename. 
	if (isSafariBrowser) { 
		dwldLink.setAttribute("target", "_blank"); 
	} 
	dwldLink.setAttribute("href", url); 
	dwldLink.setAttribute("download", filename + ".csv"); 
	dwldLink.style.visibility = "hidden"; 
	document.body.appendChild(dwldLink); 
	dwldLink.click(); 
	document.body.removeChild(dwldLink); 
} 

closePrintCard() {
  this.printCard = false;
}

startPrintCard() {
  this.printCard = true;
    this.test();
}

test() {
  this.waitForOneSecond().then((value) => {
    console.log(value);
    window.print();
  });
  }

waitForOneSecond() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve("I promise to return after one second!");
    }, 1000);
  });
}


}
