<div class="card-upload" *ngIf="cardUserBorads.length == 0 && gestInscr == false">
    <div class="card-gen-tool">
        <div class="card-gen-title">
            Préparation des fichiers
        </div>
        <div class="card-gen-bt" (click)="selecteInscr()">
            Gérer les inscrits
        </div>
    </div>
    <div class="card-gen-tool">
        <div class="card-gen-title">
            Préparation des cartes
        </div>
        <div class="card-upload-error" *ngIf="cardError">
            {{cardError}}
        </div>
        <div>
            <div>Fichier à traiter : </div>
            <div class="form-group">
                <file-upload [control]="fileUploadTreat" (change)="fileUploadInit($event, 'Treat')"></file-upload>
            </div>
        </div>
        <div>
            <div>Fichier pour filtrer la génération des cartes :</div>
            <div>
                <file-upload [control]="fileUploadInscrFilter" (change)="fileUploadInit($event, 'InscrFilter')"></file-upload>
            </div>
        </div>
        <div *ngIf="fileUploadTreat.valueChanges.getValue().length > 0">
            <div class="card-gen-bt" (click)="validateData()">
                Lancer le traitement
            </div>
        </div>
    </div>
</div>
<div *ngIf="gestInscr">
    <ng-container *ngIf="!startGestInscrProcess">
        <div class="card-gen-tool">
            <div class="card-gen-title">
                Initialisation
            </div>
            <div>
                <div>Liste d'inscrit sur le site :</div>
                <div>
                    <file-upload [control]="fileUploadInscrSite" (change)="fileUploadInit($event, 'Site')"></file-upload>
                </div>
            </div>
            <div>
                <div>Liste d'inscrit ayant rapporté l'inscription :</div>
                <div>
                    <file-upload [control]="fileUploadInscrValid" (change)="fileUploadInit($event, 'Valid')"></file-upload>
                </div>
            </div>      
            <div class="card-gen-bt-lst">
                <div class="card-gen-bt" (click)="startGestInscr()" *ngIf="fileInscrSite">
                    Lancer le traitement
                </div>
                <div class="card-gen-bt" (click)="gestInscrDiscard()">
                    Annuler
                </div>
            </div>
        </div>
    </ng-container>  
    <ng-container *ngIf="startGestInscrProcess">
        <div class="card-gen-valid-inscr-count" *ngIf="validInscrCount > 0">
            <div>
                Nombre d'inscription validée : {{ validInscrCount }}
            </div>
        </div>
        <div class="card-gen-tool">
            <div class="card-gen-title">
                Filtre(s)
            </div>
            <div class="card-gen-filter">
                <div class="card-gen-bt" (click)="filterSelect('dtLess')" [ngClass]="{'card-gen-filter-selected': selectedFilter === 'dtLess'}">
                    Sans date
                </div>
                <div class="card-gen-bt" (click)="filterSelect('dtAll')" [ngClass]="{'card-gen-filter-selected': selectedFilter === 'dtAll'}">
                    Avec date
                </div>
                <ng-container *ngFor="let processingUserDt of processingUserDtLst.sort(); index as i">
                    <div class="card-gen-bt" (click)="filterSelect(processingUserDt)" [ngClass]="{'card-gen-filter-selected': selectedFilter === processingUserDt}">
                        {{ processingUserDt }}
                    </div>
                </ng-container>
            </div>    
        </div>
        <div class="card-gen-tool">
            <div class="card-gen-title">
                Liste d'utilisateur(s)
            </div>
            <ng-container *ngFor="let processingUser of processingUserLst; index as i">
                <div class="gest-inscr-infos-line" [ngClass]="{'gest-inscr-user-with-dt-lock': processingUser.lock && processingUser.processingDt, 'gest-inscr-user-with-dt': !processingUser.lock && processingUser.processingDt}" *ngIf="!selectedFilter || selectedFilter === processingUser.processingDt || (selectedFilter === 'dtLess' && !processingUser.processingDt) || (selectedFilter === 'dtAll' && processingUser.processingDt)">
                    <div class="gest-inscr-card-photo">
                        <div class="gest-inscr-card-photo-content" [ngStyle]="{'background-image':'url('+processingUser.photo+')'}"></div>
                    </div>
                    <div class="gest-inscr-infos-user">
                        <div class="gest-inscr-infos-user-name">
                            <div class="gest-inscr-infos">
                                {{ strUcFirst(processingUser.nom) + ' ' + strUcFirst(processingUser.prenom) }}
                            </div>
                            <div class="gest-inscr-numinscr">
                                <div>
                                    {{ processingUser.numInscrVal}}
                                </div>
                            </div>
                        </div>
                        <div class="gest-inscr-infos-valid">
                            <div class="gest-inscr-infos-line-checkbox gest-inscr-infos icon icon-checkbox-unchecked gest-inscr-check-enable" (click)="gestInscrSelectAction(i)" *ngIf="!processingUser.lock && !processingUser.processingDt"></div>
                            <div class="gest-inscr-infos-line-checkbox gest-inscr-infos icon icon-checkbox-checked gest-inscr-check-enable" (click)="gestInscrSelectAction(i)" *ngIf="!processingUser.lock && processingUser.processingDt"></div>
                            <div class="gest-inscr-infos-line-checkbox gest-inscr-infos icon icon-checkbox-checked" *ngIf="processingUser.lock"></div>
                            <div [ngClass]="{'gest-inscr-dt-lock': processingUser.lock && processingUser.processingDt, 'gest-inscr-dt': !processingUser.lock && processingUser.processingDt}">
                                {{ processingUser.processingDt }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="card-gen-bt-lst">
                <div class="card-gen-bt" (click)="saveGestInscr()">
                    Enregistrer
                </div>
                <div class="card-gen-bt" (click)="saveMails()">
                    Exporter Mails
                </div>
                <div class="card-gen-bt" (click)="gestInscrDiscard()">
                    Annuler
                </div>
            </div>
        </div>    
    </ng-container>  
</div>
<div class="card-print-page" *ngIf="cardUserBorads.length > 0">
    <div class="card-print-page-bt" *ngIf="!printCard">
        <div class="card-gen-tool">
            <div class="card-gen-title">
                Filtre(s)
            </div>
            <div class="card-gen-filter">
                <div class="card-gen-bt" (click)="filterSelect('dtLess')" [ngClass]="{'card-gen-filter-selected': selectedFilter === 'dtLess'}">
                    Sans date
                </div>
                <div class="card-gen-bt" (click)="filterSelect('dtAll')" [ngClass]="{'card-gen-filter-selected': selectedFilter === 'dtAll'}">
                    Avec date
                </div>
                <ng-container *ngFor="let processingUserDt of processingUserDtLst.sort(); index as i">
                    <div class="card-gen-bt" (click)="filterSelect(processingUserDt)" [ngClass]="{'card-gen-filter-selected': selectedFilter === processingUserDt}">
                        {{ processingUserDt }}
                    </div>
                </ng-container>
            </div>    
        </div>
        <div class="card-gen-bt-lst">
            <div class="card-gen-bt" (click)="startPrintCard()">
                Imprimer
            </div>
            <div class="card-gen-bt" (click)="reset()" *ngIf="fileUploadTreat.valueChanges.getValue().length > 0">
                Annuler
            </div>
        </div>    
    </div>
    <div id="pdfContent">
        <ng-container *ngFor="let user of cardUserBorads; index as i">
            <div class="card-user" *ngIf="!selectedFilter || selectedFilter === user.processingDt || (selectedFilter === 'dtLess' && !user.processingDt) || (selectedFilter === 'dtAll' && user.processingDt)">
                <div class="card-user-content">
                    <div class="card-left-part">
                        <div class="card-title" ></div>
                        <div class="card-user-infos">
                            <div class="card-user-info-elem card-user-info-p">
                                {{user.prenom}}
                            </div>
                            <div class="card-user-info-elem card-user-info-n">
                                {{user.nom}}
                            </div>
                            <div class="card-user-info-ti">
                                {{user.type}}
                            </div>
                            <div class="card-user-info-num">
                                {{user.numInscr}}
                            </div>
                        </div>
                    </div> 
                    <div class="card-right-part">
                        <div class="card-photo" [ngStyle]="{'background-image':'url('+user.photo+')'}"></div>
                        <div class="card-dt">2019 - 2020</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
